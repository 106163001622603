import axios from '../axios'

const QuestionService = (function() {
  let instance;
  function Service() {}

  Service.prototype.findById = function(id) {
    return axios
      .get(`question_public/${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  Service.prototype.findByIds = function(ids) {
    let promises = ids.map(cid => {
      return instance.findById(cid).then(promise => promise);
    });

    return Promise.all(promises).then(questions => {
      return questions;
    });
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = new Service();
      }

      return instance;
    }
  };
})();

export default QuestionService;

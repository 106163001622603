<template>
<div>

  <div class="bg global-blue">
    <v-container v-if="isLoading == true">
      <Loader></Loader>
    </v-container>

    <v-container v-else>
      <v-card
        dark
        class="back"
        flat
        min-height="900"
      >

        <v-row justify="center">
          <v-col cols="12 hidden-sm-and-down">
            <h1 class="global-title text-no-wrap" :style="{color: 'white'}">{{ questionnaire.localizations != null && questionnaire.localizations.name[locale] != null ? questionnaire.localizations.name[locale] : questionnaire.name }}</h1>
            <h1 class="global-title text-no-wrap" :style="{'font-size': 20 + 'px'}">{{ questionnaire.organization_name[locale] }}</h1>
          </v-col>
          <v-col class="ml-12" cols="12 hidden-md-and-up">
            <h1 class="global-title text-justify" :style="{color: 'white'}">{{ questionnaire.localizations != null && questionnaire.localizations.name[locale] != null ? questionnaire.localizations.name[locale] : questionnaire.name }}</h1>
            <h1 class="global-title text-justify" :style="{'font-size': 20 + 'px'}">{{ questionnaire.organization_name[locale] }}</h1>
          </v-col>
        </v-row>
        <v-row>

          <v-tabs
            class="my-12"
            dark
            color="#f7931e"
            :background-color="$blue"
            centered
          >
            <v-tab
              v-for="(t,i) in TemplateCategories"
              :key="`_${i}`"
              class="cat-btn"
              tile
              small
              dark
              text
              @click="step = i+1"
            >{{ t.name[locale] }}</v-tab>
          </v-tabs>

        </v-row>

        <v-row
          v-for="(q,a) in questionsLoaded"
          :key="`a${a}`"
        >
          <v-card
            class="back my-n3"
            dark
            tile
            flat
            v-if="!q.empty"
          >
            <v-row>
              <h2>{{ q.header[locale] }}</h2>
            </v-row>
            <v-row>
              <template v-if="q.groups.length > 0">
                <template v-for="(g,indexGroups) in q.groups">
                  <v-card
                    class="back"
                    max-width="500"
                    min-width="300"
                    :key="indexGroups"
                    flat
                    outlined
                  >
                    <v-icon
                      v-if="q.tag == 'expertise'"
                      class="mdi-36px"
                    >mdi-account-circle</v-icon>

                    <template v-for="(input, k) in q.inputs">
                      <template v-if="input.group == g && input.tag !== 'employeeselection' && input.answer != null && input.answer[locale] != null  && input.answer[locale] !== ''">
                        <div
                          v-if="input.answer != null && input.answer[locale] != null"
                          :key="`2_${k}`"
                        >
                          <v-divider
                            v-if="input.answer[locale] != null && input.answer[locale] !== false"
                            inset
                            class="ma-2"
                          ></v-divider>

                          <!-- MAP -->
                          <template v-if="input.type == 'map'">
                            <v-btn
                              class="mx-n3 ma-2"
                              @click="openlink(`http://maps.google.com/maps?q=${input.answer[locale].toString()}`)"
                              small
                              text
                            >
                              <v-icon>mdi-map-marker</v-icon>google maps
                            </v-btn>
                          </template>

                          <!-- CHECKBOX -->
                          <template v-if="input.type == 'checkbox' && input.answer[locale] == true">
                            <p class="font-weight-bold">{{ input.label[locale] }}</p>

                          </template>

                          <!-- TEXT -->
                          <template v-if="input.type === 'text' && input.type !== 'textarea' && input.answer[locale] !== ''">
                            <p class="ma-auto">{{ input.answer[locale] }}</p>
                          </template>

                          <!-- TEXTAREA -->
                          <template v-if="input.type === 'textarea' && input.type !== 'text' && input.answer[locale] !== ''">
                            <!-- <v-sheet class="back mx-n8"> -->
                            <p class="ma-auto">{{ input.answer[locale] }}</p>
                            <!-- </v-sheet> -->
                          </template>

                          <!-- TRL CASE SPECIFIC -->
                          <template v-if="input.tag == 'trlcasespecific'">
                            <p class="ma-auto">{{ input.label[locale] }}: {{ input.answer[locale] }} - {{ portal_info.info[input.answer[locale]-1][locale] }}</p>

                          </template>

                          <!-- EMAIL -->
                          <template v-if="input.type == 'email'">
                            <p class="ma-auto">{{ input.answer[locale] }}</p>
                          </template>

                          <!-- RADIO -->
                          <template v-if="input.type == 'radiolabels'">
                            <template v-for="(label, labelkey) in input.labels[locale]">
                              <p
                                class="ma-auto"
                                v-if="input.answer[locale] == labelkey"
                                :key="labelkey"
                              >{{ label }}</p>
                            </template>
                          </template>

                          <!-- FILE -->
                          <template v-if="input.type == 'file'">
                            <p>{{ locale == 'fi' ? 'liitteet' : 'Attachments' }}:</p>

                            <v-chip
                              small
                              class="mt-n4 mr-2"
                              color="#f7931e"
                              @click="fetchFile(item.fileId)"
                              v-for="(item, itemIndex) in input.answer[locale]"
                              :key="itemIndex"
                            >{{ item.fileName }}</v-chip>
                          </template>
                        </div>
                      </template>
                    </template>
                    <v-divider
                      inset
                      class="ma-2"
                    ></v-divider>
                  </v-card>
                </template>
              </template>
              <template v-else-if="q.inputs != null">
                <template v-for="(input, k) in q.inputs">
                  <div
                    :key="`single_${k}`"
                    v-if="input.answer != null && input.answer[locale] != null && input.answer[locale] !== false"
                  >

                    <!-- TEXT -->
                    <template v-if="input.type == 'text' || input.type == 'textarea'">
                      <p class="mx-4">{{ input.answer[locale] }}</p>
                      <v-chip
                        class="mx-4"
                        @click="openlink(input.answer[locale])"
                        v-if="input.label.fi == 'Kotisivu'"
                      >{{ locale == 'fi' ? 'Vieraile' : 'Visit' }}</v-chip>
                    </template>
                    <!-- CHECKBOX -->
                    <template v-if="input.type == 'checkbox' && input.answer[locale] == true">
                      <p class="font-weight-bold my-3 mx-4">{{ input.label[locale] }}</p>
                      <template v-if="input.inputs != null">

                        <div
                          class="mx-4"
                          v-for="(innerInput, iKey) in input.inputs"
                          :key="`inner${iKey}`"
                        >
                          {{ innerInput.answer[locale] == true ? '- ' + innerInput.label[locale] : '' }}

                        </div>

                      </template>
                    </template>
                    <!-- FILE -->
                    <template v-if="input.type == 'file'">
                      <p class="mx-4">{{ locale == 'fi' ? 'liitteet' : 'Attachments' }}:</p>

                      <v-chip
                        small
                        class="mt-n4 mx-4"
                        color="#f7931e"
                        @click="fetchFile(item.fileId)"
                        v-for="(item, itemIndex) in input.answer[locale]"
                        :key="itemIndex"
                      >{{ item.fileName }}</v-chip>
                    </template>
                    <!-- TRL -->
                    <template v-if="input.type == 'rangeslider' && input.answer[locale].length == 2">
                      <v-list
                        disabled
                        dense
                        rounded
                        class="back"
                      >
                        <v-list-item>
                          <h1 class="ml-n4 mb-8">{{ input.answer[locale][0] }} - {{input.answer[locale][1]}}</h1>
                        </v-list-item>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(item, infoindex) in q.info"
                            :key="`info_${infoindex}`"
                          >
                            <template v-if="infoindex+1 >= input.answer[locale][0] && infoindex+1 <= input.answer[locale][1]">
                              <v-list-item-avatar
                                class="my-n1"
                                :color="item.color"
                                size="38"
                              >
                                <span class="white--text headline">{{ item.id }}</span>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-text="item[locale]"></v-list-item-title>
                              </v-list-item-content>
                            </template>

                            <template v-else>
                              <v-list-item-avatar
                                class="my-n1"
                                color="#002135"
                                size="38"
                              >
                                <span class="white--text headline">{{ item.id }}</span>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item[locale]"
                                  class="trl-inactive"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </template>

                  </div>
                </template>
              </template>
            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </v-container>
    <template v-if="err">
      <error
        :msg="error"
        :ok="reset"
      ></error>
    </template>
  <div class="bg-sm">

  </div>
  </div>
  
</div>
</template>

<style scoped>
.bg-sm {
  height: 140px;
  width: 100%;
}

.bg {
  height: 100%;
  margin-bottom: 100px;
}

.back {
  padding: 32px;
  margin: 16px;
  width: 100%;
  background: var(--v-primary-base) !important;
}

.title-env {
  color: white;
  font-size: 32px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
  color: #f7941e;
}

.trl-inactive {
  color: grey;
}

/* .cat-btn {
  border: 1px solid #f7941e;
} */
</style>

<script>
import questionnaireService from "../../services/questionnaires";
import categoryService from "../../services/categories";
import questionService from "../../services/questions";
import templateService from "../../services/template";
import { QUESTIONNAIRE_SHOW, PORTAL_INFO } from "../../constants/translations";
import axios from "axios";

export default {
  name: "QuestionnairesShow",
  components: {
    Loader: () => import("../../components/Loader.vue"),
    Error: () => import("../../components/Error")
  },
  data() {
    return {
      step: 1,
      questionnaire: {},
      categories: [],
      TemplateCategories: [],
      isLoading: true,
      questionsLoaded: [],
      translations: QUESTIONNAIRE_SHOW,
      portal_info: PORTAL_INFO,
      headers: [],
      err: false,
      error: {},
      baseURL: process.env.VUE_APP_URL
    };
  },
  methods: {
    reset() {
      this.err = false;
    },
    fetchquestions(value) {
      return questionService
        .getInstance()
        .findByIds(this.categories[value - 1].questionIds)
        .then(questions => {
          // console.log(questions);
          this.isLoading = false;
          this.questionsLoaded = questions.map(q => {
            q.empty = true;

            q.inputs = q.inputs.map(i => {
              if (
                i.answer != null &&
                i.answer[this.locale] != null &&
                i.answer[this.locale] != ""
              ) {
                q.empty = false;
              }

              return i;
            });
            return q;
          });
        })
        .catch(err => {
          this.error = {
            fi: "Kategorioiden hakeminen epäonnistui",
            en: "There was an issue with fetching the categories",
            err
          };
          this.err = true;
        });
    },
    /* fetchFile(id) {
      if (id) {
        const url = `${baseURL}/question/file/${id}`;
        window.open(url, `_blank`);
      }
    }, */
    fetchFile(id) {
      axios({
        url: `${this.baseURL}/api/portal/question/file/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          window.open(url, `_blank`);
        })
        .catch(err => {
          this.$store.commit("giveErrorMessage", {
            msg: {
              fi: "Virhe tiedoston lataamisessa",
              en: "Error loading the file"
            }
          });
        });
    },
    openlink(url) {
      if (!url) {
        return;
      }
      window.open(url, `_blank`);
    }
  },
  watch: {
    step: {
      handler() {
        this.fetchquestions(this.step);
      }
    },
    $route(to) {
      if (this.saved === true) {
        this.$router.push({ name: to.params.id });
      } else {
        this.$router.push({ name: to.params.id });
      }
    }
  },
  created() {
    templateService
      .getInstance()
      .getTemplateCategories()
      .then(categories => {
        categories.pop();
        this.TemplateCategories = [...categories];
      })
      .catch(err => {
        this.error = {
          fi: "Kategorioiden hakeminen epäonnistui",
          en: "There was an issue with fetching the categories",
          err
        };
        this.err = true;
      });

    questionnaireService
      .getInstance()
      .findById(this.$route.params.id)
      .then(questionnaire => {
        this.questionnaire = { ...questionnaire };

        categoryService
          .getInstance()
          .findByIds(questionnaire.categoryIds)
          .then(categories => {
            this.categories = categories.slice();

            this.fetchquestions(1).then(err => {
              this.error = {
                fi: "Kehitysympäristöjen hakeminen epäonnistui",
                en:
                  "There was an issue with fetching development enironments' data",
                err
              };
              this.err = true;
            });
          });
      })
      .catch(err => {
        this.error = {
          fi: "Kehitysympäristöjen hakeminen epäonnistui",
          en: "There was an issue with fetching development enironments' data",
          err
        };
        this.err = true;
      });
  },
  computed: {
    locale() {
      this.fetchquestions(this.step);
      return this.$store.state.locale;
    }
  }
};
</script>
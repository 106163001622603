import axios from '../axios'

const  QuestionnaireService = (function(){
    
    
    let instance;
    function Service(){}

    Service.prototype.findById = function(id)
    {
        return axios.get(`questionnaires/${id}`).then((res) => {
            return res.data;
        }).catch((err) => {
            return err;
        })
    }

    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
})()

export default QuestionnaireService





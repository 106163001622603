import axios from '../axios'

const  TemplateService = (function(){
    
    let instance;
    function Service(){}

    Service.prototype.getTemplateCategories = function(){

        return axios.get(`template/categories`).then((response) => {
            return response.data
        }).catch((err) => {
            return err
        })

    }

    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
})()

export default TemplateService

import axios from '../axios'

const  CategoryService = (function(){
        
    let instance;
    function Service(){}

    Service.prototype.findById = function(id)
    {
        
        return axios.get(`category/${id}`).then((res) => {

            return res.data;
            
        }).catch((err) => {
            return err;
        })
    }

    Service.prototype.findByIds = function(ids){

        let promises = ids.map(cid => {
            return instance.findById(cid).then(promise => promise)
          })
  
          return Promise.all(promises).then(categories => {
            return categories
          })
    }
    
    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
})()

export default CategoryService




